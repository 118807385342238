import * as RadixPrimivites from '@radix-ui/react-toast';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

import type { ToastDirection } from '../types';

export interface Props extends RadixPrimivites.ToastProviderProps {
  direction?: ToastDirection;
  maxLength?: number;
  usePortal?: boolean;
}

interface ToastConfigInfo {
  direction: ToastDirection;
  duration?: number;
  usePortal?: boolean;
}

interface IToastContext {
  toastContext: ToastConfigInfo;
  setToastContext: Dispatch<SetStateAction<ToastConfigInfo>>;
}

const ToastContext = createContext<IToastContext>({
  toastContext: {
    direction: 'right',
    duration: 5000,
    usePortal: false,
  },
  setToastContext: () => undefined,
});

export function Provider({
  children,
  direction = 'right',
  duration = 5000,
  usePortal = false,
  ...props
}: PropsWithChildren<Props>) {
  const [toastContext, setToastContext] = useState<ToastConfigInfo>({
    direction,
    duration,
    usePortal,
  });

  //스토리북에서 direction, duration 변경시 적용을 위함.
  useEffect(() => {
    setToastContext((prev) => ({ ...prev, direction }));
  }, [direction]);

  useEffect(() => {
    setToastContext((prev) => ({ ...prev, duration }));
  }, [duration]);

  const values = {
    toastContext,
    setToastContext,
  };

  return (
    <ToastContext.Provider value={values}>
      <RadixPrimivites.Provider
        swipeDirection={direction}
        duration={duration}
        {...props}
      >
        {children}
      </RadixPrimivites.Provider>
    </ToastContext.Provider>
  );
}

export const useToastContext = () => useContext(ToastContext);

export default Provider;
