import { useAtom, useAtomValue } from 'jotai';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Switch } from '@daouoffice/ui/lib/foundation/Switch';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import * as requestConfigApi from './apis/requestConfig';
import { RequestList } from './RequestList';
import { DetailInfoDialog } from './DetailInfoDialog';
import { requestInfoDialogOpenAtom } from './store/requestInfoDialogOpenAtom';
import { selectedRequestInfoAtom } from './store/selectedRequestInfoAtom';

export function DataRequestManagement() {
  const { t } = useTranslation();

  const { data: configData, error } = useQuery({
    queryKey: [requestConfigApi.QUERY_KEY],
    queryFn: requestConfigApi.getRequestConfig,
  });

  if (error) {
    console.error(
      `Error: GlobalConfig > DataRequestManagement : ${error.message}`,
    );
  }

  const queryClient = useQueryClient();

  const configMutation = useMutation({
    mutationFn: async (set: boolean) =>
      requestConfigApi.updateRequestConfig(set),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [requestConfigApi.QUERY_KEY],
      }),
    onError: (error) =>
      console.error(
        `Error: GlobalConfig > DataRequestManagement > RequestConfig : ${error.message}`,
      ),
  });

  const [openAtom, setOpenAtom] = useAtom(requestInfoDialogOpenAtom);
  const selectedInfoId = useAtomValue(selectedRequestInfoAtom);

  return (
    <div className="px-[72px] pt-[40px]">
      <div className="p-[40px] border border-solid border-[#DDDDDD] rounded-[8px]">
        <p className="text-[22px] text-[#333333] font-[500]">
          {t('dataManagement.requestManagement.functionTitle')}
        </p>
        <div className="flex flex-wrap items-center mt-[8px] py-[16px] gap-[8px] justify-between">
          <p className="mr-[24px] text-[16px] text-[#111111] font-[500] min-w-[160px] max-w-[240px] flex-grow">
            {t('dataManagement.requestManagement.useFunction')}
          </p>
          <div className="flex flex-wrap flex-col items-start flex-grow">
            <p className="text-[14px] text-[#AAAAAA] font-[400]">
              {t('dataManagement.requestManagement.description1')}
            </p>
            <p className="text-[14px] text-[#AAAAAA] font-[400]">
              {t('dataManagement.requestManagement.description2')}
            </p>
          </div>
          <Switch
            className="self-baseline"
            id="GlobalConfig.Datamanagement.RequestManagement.Switch"
            defaultChecked={configData?.data ?? false}
            onChange={(e) => void configMutation.mutate(e)}
          />
        </div>
      </div>
      <RequestList />
      <DetailInfoDialog
        infoId={selectedInfoId}
        open={openAtom}
        onOpenChange={setOpenAtom}
      />
    </div>
  );
}
