import {
  FILTER_OPTION_DATE_30DAYS,
  FILTER_OPTION_DATE_7DAYS,
  FILTER_OPTION_DATE_90DAYS,
  FILTER_OPTION_DATE_SELF_INPUT,
  FILTER_OPTION_DATE_TODAY,
} from '../../constants';
import { DateFilterOptionTitle, FilterOption } from '../../types';
import { Root } from './Root';

export interface Props {
  title: string;
  filterKey: string;
  dateOptionTitles: DateFilterOptionTitle;
}

export function DateFilter({ title, filterKey, dateOptionTitles }: Props) {
  const dateOptions: FilterOption[] = [
    {
      key: FILTER_OPTION_DATE_TODAY,
      title: dateOptionTitles.today,
      value: '0',
    },
    {
      key: FILTER_OPTION_DATE_7DAYS,
      title: dateOptionTitles.days7,
      value: '7',
    },
    {
      key: FILTER_OPTION_DATE_30DAYS,
      title: dateOptionTitles.days30,
      value: '30',
    },
    {
      key: FILTER_OPTION_DATE_90DAYS,
      title: dateOptionTitles.days90,
      value: '90',
    },
    {
      key: FILTER_OPTION_DATE_SELF_INPUT,
      title: dateOptionTitles.selfInput,
      value: 'selfInput',
    },
  ];

  return (
    <Root
      title={title}
      filterKey={filterKey}
      filterType="date"
      options={dateOptions}
    />
  );
}
