import { useTranslation } from '../../lib/i18n/client/useTranslation';

import NodeList from './NodeList';
import { NodeProps } from './interface';

export interface Props {
  departments: Array<NodeProps>;
  users: Array<NodeProps>;
  isSearching: boolean;
}

export default function SearchResult({
  departments = [],
  users = [],
  isSearching,
}: Props) {
  const { t } = useTranslation();
  return (
    <>
      {!!departments.length && (
        <>
          <p className="title body_small regular">{t('부서')}</p>
          <NodeList nodes={departments} isSearch={true} />
        </>
      )}
      {!!users.length && (
        <>
          <p className="title body_small regular">{t('이름')}</p>
          <ul className="group_organization">
            <NodeList nodes={users} />
          </ul>
        </>
      )}
      {!departments.length && !users.length && (
        <div className="no_data">
          <p className="body_medium regular">
            {isSearching ? t('검색중입니다') : t('검색결과가 없습니다')}
          </p>
        </div>
      )}
    </>
  );
}
