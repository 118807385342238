import * as React from 'react';
import { MemberDataInfo } from '../../types';

export interface IValuesContext {
  selectedRows: MemberDataInfo[];
}

export interface IActionsContext {
  setSelectedRows: React.Dispatch<React.SetStateAction<MemberDataInfo[]>>;
}

export const ValuesContext = React.createContext<IValuesContext>({
  selectedRows: [],
});

export const ActionsContext = React.createContext<IActionsContext>({
  setSelectedRows: () => undefined,
});

export function Provider({ children }: React.PropsWithChildren) {
  const [selectedRows, setSelectedRows] = React.useState<MemberDataInfo[]>([]);

  return (
    <ValuesContext.Provider value={{ selectedRows }}>
      <ActionsContext.Provider value={{ setSelectedRows }}>
        {children}
      </ActionsContext.Provider>
    </ValuesContext.Provider>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return { ...values, ...actions };
};
