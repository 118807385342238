import { atom } from 'jotai';

export const selectedAppIdAtom = atom<string>('');

export const selectedAppPathAtom = atom((get) => {
  const appId = get(selectedAppIdAtom);
  return `/config/${appId}`;
});

export const setSelectedAppIdAtom = atom(null, (_, set, appId: string) =>
  set(selectedAppIdAtom, appId),
);

export const setSelectedAppIdByPathAtom = atom(
  null,
  (_, set, info: { path: string; contextPath: string }) => {
    const { path, contextPath } = info;
    const appId = path.replace(`${contextPath}`, '').replace('/config/', '');
    set(selectedAppIdAtom, appId);
  },
);
