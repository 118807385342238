import OrgChart from '../../../../../../../components/OrgChart';
import { NodeProps } from '../../../../../../../components/OrgChart/interface';
import { useContext } from '../Provider';
import { AppContents } from './AppContents';
import { DefaultContents } from './DefaultContents';

export function Contents() {
  const { selectedAppType, setDeptName, setDeptId } = useContext();

  const nodeClickHandler = (node: NodeProps) => {
    setDeptName(
      node.nodeType === 'DEPARTMENT'
        ? node.name
        : node?.departmentName ?? node.name,
    );
    node.departmentId && setDeptId(node.departmentId);
  };

  return (
    <>
      <OrgChart
        style={{
          zIndex: 0,
          boxShadow: 'none',
          position: 'inherit',
          scrollBehavior: 'auto',
        }}
        onNodeClick={nodeClickHandler}
        useCheckbox={false}
      />
      {selectedAppType ? <AppContents /> : <DefaultContents />}
    </>
  );
}

export default Contents;
