import { Button, IconButton } from '@daouoffice/ui';
import { DeleteAttachFile, RestoreIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { ArrowLeftIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import * as deleteAttachFileMutation from '../../../../api/deleteAttachFileMutation';
import * as permanentDeleteMutation from '../../../../api/permanentDeleteMutation';
import { useContext } from '../../../Provider';

export function Header() {
  const { deptName, deptId, selectedAppType, setDeptId, setDeptName } =
    useContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const deleteMutation = useMutation({
    mutationFn: () =>
      deleteAttachFileMutation.deleteAttachAllFileMutation(
        deptId ? [deptId] : [],
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [deleteAttachFileMutation.QUERY_KEY, 'all'],
      });
    },
  });
  const deletePermanentMutation = useMutation({
    mutationFn: () =>
      permanentDeleteMutation.permanentDeleteMutation(deptId ? [deptId] : []),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [permanentDeleteMutation.QUERY_KEY, 'all'],
      });
    },
  });

  const onClickDeleteAttachFileHandler = () => {
    if (
      confirm(
        t('globalconfig.data.dept.deleted.dialog.delete.allattachfile.contents')
          .replace('{deptName}', deptName ?? '')
          .replace(
            '{selectedAppType}',
            t(`globalconfig.data.app.${selectedAppType}.title`),
          ),
      )
    ) {
      try {
        deleteMutation.mutate();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onClickDeleteDeptHandler = () => {
    if (
      confirm(
        t(
          'globalconfig.data.dept.deleted.dialog.delete.permanent.contents',
        ).replace('{deptName}', deptName ?? ''),
      )
    ) {
      // TODO: API 연동 필요.
      try {
        deletePermanentMutation.mutate();
        setDeptId(undefined);
        setDeptName(undefined);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onClickRestoreHandler = () => {
    if (
      confirm(
        t('globalconfig.data.dept.deleted.dialog.restore.contents').replace(
          '{deptName}',
          deptName ?? '',
        ),
      )
    ) {
      // TODO: API 연동 필요.
      try {
        // const queryClient = new QueryClient();
        // // await queryClient
        // //   .fetchQuery({
        // //     queryKey: ['dataDownloadQuery', deptId, selectedAppType],
        // //     queryFn: () => ,
        // //   })
        // 삭제 성공 시, 부서 목록으로 이동
        setDeptId(undefined);
        setDeptName(undefined);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const titleBlock = (
    <div className="flex justify-between">
      <div className="flex items-center gap-2">
        <IconButton
          title="btn_prev"
          onClick={() => {
            setDeptName(undefined);
            setDeptId(undefined);
          }}
        >
          <ArrowLeftIcon className="size-6" />
        </IconButton>
        <span className="px-[6px] py-[2px] text-white bg-[#A7A7A7] rounded-[8px] font-medium">
          {t('globalconfig.dept.status.deleted')}
        </span>
        <h2 className="font-medium text-2xl">{deptName}</h2>
      </div>
    </div>
  );

  const actionButtonListBlock = (
    <div className="flex items-center gap-2">
      {selectedAppType !== 'approval' && (
        // TODO: DeleteAttachFile Icon 중앙이 맞지 않아 디자인팀 요청 필요.
        <Button
          styleType="line"
          colorset="major"
          title={t('globalconfig.data.app.delete.attachfile')}
          onClick={onClickDeleteAttachFileHandler}
          className={clsx(
            'flex !border !border-solid rounded-lg px-4 py-2 items-center border-[#AAAAAA]',
          )}
        >
          <DeleteAttachFile className="relative top size-5 stroke-[#363636] top-[4px]" />
        </Button>
      )}
      <Button
        styleType="line"
        colorset="major"
        title={t('globalconfig.data.app.delete.permenant')}
        onClick={onClickDeleteDeptHandler}
        className={clsx(
          'flex gap-2 !border !border-solid rounded-lg px-4 py-2 items-center border-[#AAAAAA]',
        )}
      >
        <TrashIcon className="size-4 stroke-[#363636]" />
      </Button>
      <Button
        styleType="line"
        colorset="major"
        title={t('globalconfig.data.app.restore')}
        onClick={onClickRestoreHandler}
        className={clsx(
          'flex !border !border-solid rounded-lg px-4 py-2 items-center border-[#AAAAAA]',
        )}
      >
        <RestoreIcon className="relative top size-5 stroke-[#363636] top-[4px]" />
      </Button>
    </div>
  );

  return (
    <div className="flex justify-between">
      {titleBlock}
      {actionButtonListBlock}
    </div>
  );
}

export default Header;
