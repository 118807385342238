import { Button, IconButton } from '@daouoffice/ui';
import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import {
  DeleteAttachFile,
  FolderUpIcon,
} from '@daouoffice/ui/lib/icons/dop/24';
import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import {
  QueryClient,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { clsx } from 'clsx';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NodeProps } from '../../../../../../../../components/OrgChart/interface';
import { formatFileNameWithDate } from '../../../../../../../../lib/utils/date/withLocale';
import OrgChart from '../../../../../../../OrgChart';
import * as deleteAttachFileMutation from '../../../api/deleteAttachFileMutation';
import { detailDataDownloadQuery } from '../../../api/detailDataDownloadQuery';
import { useContext } from '../../Provider';

export function Header() {
  const {
    selectedAppType,
    deptId,
    deptName,
    selectedRows,
    setSelectedAppType,
  } = useContext();
  const { t } = useTranslation();
  const isDisabled = selectedRows.length === 0;
  const [deptSelectOpenState, setDeptSelectOpenState] = useState(false);
  const [, setDesignatedDeptId] = useState<number>();
  const [, setDesignatedDeptName] = useState<string>();
  const queryClient = useQueryClient();
  const deleteMutation = useMutation({
    mutationFn: () =>
      selectedAppType === 'report'
        ? deleteAttachFileMutation.deleteReportAttachFileMutation()
        : deleteAttachFileMutation.deleteBoardAttachFileMutation(),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [deleteAttachFileMutation.QUERY_KEY, selectedAppType],
      });
    },
  });

  const onClickDownloadButtonHandler = async () => {
    try {
      const queryClient = new QueryClient();
      await queryClient
        .fetchQuery({
          queryKey: ['dataDownloadQuery', deptId, selectedAppType],
          queryFn: () => detailDataDownloadQuery(deptId, selectedAppType),
        })
        .then((blob: Blob | undefined) => blob && blobHandler(blob));
    } catch (error) {
      console.error(error);
    }
  };

  const onClickDeleteAttachFileHandler = () => {
    if (
      confirm(
        t('globalconfig.data.dept.delete.attachfile.dialog.contents')
          .replace('{deptName}', deptName ?? '')
          .replace(
            '{selectedAppType}',
            t(`globalconfig.data.app.${selectedAppType}.title`),
          ),
      )
    ) {
      deleteMutation.mutate();
    }
  };

  const blobHandler = (blob: Blob) => {
    const blobURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = formatFileNameWithDate(
      t('globalconfig.data.dept.deleted.download.title'),
      new Date(),
      'csv',
    );
    link.click();
  };

  const nodeSelectHandler = (node: NodeProps) => {
    setDeptSelectOpenState(false);
    const selectedNodeName =
      node.nodeType === 'DEPARTMENT'
        ? node.name
        : node?.departmentName ?? node.name;
    setDesignatedDeptName(selectedNodeName);
    node.departmentId && setDesignatedDeptId(node.departmentId);
    if (
      confirm(
        t(`globalconfig.data.dept.dialog.contents.${selectedAppType}`).replace(
          '{deptName}',
          selectedNodeName ?? '',
        ),
      )
    ) {
      // const queryClient = new QueryClient();
      // await queryClient.fetchQuery({
      // })
      // TODO: 이관 API 기능 동작 후, 추가 필요
    }
  };

  const titleBlock = (
    <div className="flex items-center">
      <IconButton
        title="btn_prev"
        onClick={() => setSelectedAppType(undefined)}
      >
        <ArrowLeftIcon className="w-6 h-6" />
      </IconButton>
      <h2 className="ms-2 font-medium text-[22px] -tracking-[0.88px] mr-3">
        {t(`globalconfig.data.app.${selectedAppType}.title`)}
      </h2>
      <hr className="!m-0 w-[1px] h-3 bg-[#E2E5E9]" />
      <span className="font-normal ml-3 text-base text-[#AAA]">{deptName}</span>
    </div>
  );

  const deptSelectBlock = (
    <Dialog.Root
      open={deptSelectOpenState}
      onOpenChange={setDeptSelectOpenState}
    >
      <Dialog.Trigger>
        <Button
          title={t(`globalconfig.data.app.${selectedAppType}.move`)}
          styleType="line"
          colorset="major"
          disabled={isDisabled}
          className={clsx(
            'flex gap-2 !border !border-solid rounded-lg px-4 py-2 items-center border-[#d8d8d8]',
            {
              'text-[#AAAAAA]': isDisabled,
              'text-[#363636]': !isDisabled,
            },
          )}
          onClick={() =>
            selectedRows.length > 0 && setDeptSelectOpenState(true)
          }
        >
          <FolderUpIcon
            className={clsx('size-5', {
              'stroke-[#AAAAAA]': isDisabled,
              'stroke-[#363636]': !isDisabled,
            })}
          />
        </Button>
      </Dialog.Trigger>
      <Dialog.Content size="resizeable">
        <OrgChart
          style={{
            top: '256px',
            right: '92px',
            left: 'auto',
            bottom: '18px',
            width: '300px',
          }}
          onNodeClick={nodeSelectHandler}
          useCheckbox={false}
        />
      </Dialog.Content>
    </Dialog.Root>
  );

  const actionButtonListBlock = (
    <div className="flex items-center gap-2">
      {/* TODO: Context 지정해, Row 선택 없을 시 disabled */}
      {deptSelectBlock}
      {selectedAppType !== 'approval' && selectedAppType !== 'group' && (
        // TODO: DeleteAttachFile Icon 중앙이 맞지 않아 디자인팀 요청 필요.
        <Button
          styleType="line"
          colorset="major"
          title={t('globalconfig.data.app.delete.attachfile')}
          onClick={onClickDeleteAttachFileHandler}
          className={clsx(
            'flex !border !border-solid rounded-lg px-4 py-2 items-center border-[#d8d8d8]',
          )}
        >
          <DeleteAttachFile className="relative top size-5 stroke-[#363636] top-[5px]" />
        </Button>
      )}
      <Button
        styleType="line"
        colorset="major"
        title={t('globalconfig.data.app.list.download')}
        onClick={() => void onClickDownloadButtonHandler()}
        className={clsx(
          'flex gap-2 !border !border-solid rounded-lg px-4 py-2 items-center border-[#d8d8d8]',
        )}
      >
        <ArrowDownTrayIcon className="size-4 stroke-[#363636]" />
      </Button>
    </div>
  );

  const approvalRouteBlock = (
    <div className="flex items-center gap-2">
      <span className="text-sm text-[#AAA] font-normal -tracking-[0.28px]">
        {t('globalconfig.data.app.approval.help')}
      </span>
      {/* TODO: 바로가기 URL 생성시 추가 필요 */}
      <Link
        className="flex h-6 py-[2px] ps-2 pe-1 items-center bg-white border border-[#DEDEDE] rounded-lg gap-[2px]"
        href={''}
      >
        {t('바로가기')}
        {<ChevronRightIcon className="size-3 stroke-[#A3A3A3]" />}
      </Link>
    </div>
  );

  useEffect(() => {
    selectedRows.length === 0 && setDeptSelectOpenState(false);
  }, [setDeptSelectOpenState, selectedRows]);

  return (
    <>
      <div className="flex items-center justify-between">
        {titleBlock}
        {actionButtonListBlock}
      </div>
      {selectedAppType === 'approval' && approvalRouteBlock}
    </>
  );
}

export default Header;
