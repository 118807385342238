import { fetch } from '../../../../../../lib/fetch/client';
import { AppOverView } from '../types';

export const QUERY_KEY = 'deptEntireDataQuery';

// TODO: 추후 API 개발 후 수정 필요
const MOCK_URL_PATH = '/gw/ad/api/deletedept';

export interface ResponseDeptEntireData {
  deptId?: number;
  createdAt?: string;
  deletedAt?: string;
  attachSize?: number;
  appList?: AppOverView[];
}

interface ResponseBody {
  code?: number;
  message?: string;
  data?: ResponseDeptEntireData;
}

export async function deptEntireDataQuery(deptId?: number) {
  try {
    if (!deptId) return {};
    const FETCH_URL = `${MOCK_URL_PATH}/${deptId}`;
    const res = (await fetch(FETCH_URL)).json() as ResponseBody;
    return res;
  } catch (error) {
    console.error(error);
  }
}
