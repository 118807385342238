import { DataUsingApps } from './DataUsingApps';
import { PresentCondition } from './PresentCondition';
import { UsagePerPortal } from './UsagePerPortal';

export function CurrentDataUsage() {
  return (
    <div className="px-[72px] pt-[40px]">
      <div className="flex gap-[32px]">
        <PresentCondition />
        <UsagePerPortal />
      </div>
      <DataUsingApps />
    </div>
  );
}
