import { Pagination } from '@daouoffice/ui/lib/labs/Pagination';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import * as memberDataListQuery from '../../api/memberDataListQuery';
import { Option } from '../../types';
import { Header } from './Header';
import { MemberDataInfoTable } from './MemberDataInfoTable';
import { Provider } from './Provider';

export function MemberDataCard() {
  const [currentPage, setCurrentPage] = useState(0);
  const lastPage = 11;
  const onPageChangeHandler = (page: number) => setCurrentPage(page);
  const {
    data: res,
    error,
    refetch,
  } = useSuspenseQuery({
    queryKey: [memberDataListQuery.QUERY_KEY],
    queryFn: () =>
      memberDataListQuery.memberDataListQuery({
        size: 5,
        page: currentPage,
      } as Option),
  });

  useEffect(() => {
    refetch({}).catch((error) => console.error(error));
  }, [currentPage, refetch]);

  if (error) {
    return <>{error}</>;
  }

  return (
    <div className="flex flex-col w-full gap-6">
      <Provider>
        <Header
          onClickSyncStorage={() => {
            refetch().catch((error) => console.error(error));
          }}
        />
        <MemberDataInfoTable contents={res ? res?.elements ?? [] : []} />
        <Pagination
          currentPage={currentPage}
          onPreviousPage={onPageChangeHandler}
          onNextPage={onPageChangeHandler}
          onClickPage={onPageChangeHandler}
          onFirstPage={() => setCurrentPage(1)}
          onLastPage={() => setCurrentPage(lastPage)}
          maxPageCount={res?.page?.pageSize ?? 10}
          totalPage={res?.page?.pageSize ?? lastPage}
        />
      </Provider>
    </div>
  );
}

export default MemberDataCard;
