import { ToastProps } from '@radix-ui/react-toast';
import { PropsWithChildren, createContext, useContext, useState } from 'react';

export interface Props extends ToastProps {}

interface IToastStatus {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ToastStatusContext = createContext<IToastStatus>({
  open: false,
  setOpen: () => undefined,
});

function Container({
  children,
  open: defaultOpen,
  onOpenChange: defaultOnOpenChange,
}: PropsWithChildren<Props>) {
  const [customOpen, setCustomOpen] = useState(false);

  const isControlled =
    defaultOpen !== undefined && defaultOnOpenChange !== undefined;

  const open = isControlled ? defaultOpen : customOpen;
  const setOpen = isControlled ? defaultOnOpenChange : setCustomOpen;

  const values = {
    open,
    setOpen,
  };
  return (
    <ToastStatusContext.Provider value={values}>
      {children}
    </ToastStatusContext.Provider>
  );
}
export const useToastStatusContext = () => useContext(ToastStatusContext);

export default Container;
