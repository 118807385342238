/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAtom } from 'jotai';
import { NodeProps } from './interface';
import { getSelectedNodes, treesAtom } from './Store';
import { getNodeId } from './Util';

export default function SelectedNodes() {
  const [trees] = useAtom(treesAtom);
  const selectedNodes = getSelectedNodes(trees);

  return (
    <div className="wrap_colleague scroll_y">
      <ul className="list_tag">
        {/* {selectedNodes.map((item: NodeProps) => (
          <li key={getNodeId(item)}>
            <span className="tag">
              <a className="photo">
                <img
                  title={`${item.name} ${item.position ?? ''}`}
                  data-pin-nopin="true"
                  alt=""
                />
              </a>
              <span className="txt els">{`${item.name} ${item.position ?? ''}`}</span>
            </span>
          </li>
        ))} */}
      </ul>
    </div>
  );
}
