import { useState } from 'react';
import { Button } from '@daouoffice/ui/lib/foundation/Button';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';

export function SetRetentionPeriod() {
  const { t } = useTranslation();

  const defaultPeriod = 90;
  const selfInputOptionKey = 'standard.selfInput';

  const [retentionPeriod, setRetentionPeriod] = useState(defaultPeriod);
  const [isCustomInputVisible, setIsCustomInputVisible] = useState(false);

  const onSelectPeriod = (value: string) => {
    if (value === selfInputOptionKey) {
      setIsCustomInputVisible(true);
    } else {
      setRetentionPeriod(Number(value));
      setIsCustomInputVisible(false);
    }
  };

  const onChangePeriod = (value: number) => {
    if (value < 1) {
      setRetentionPeriod(1);
    } else if (value > 180) {
      setRetentionPeriod(180);
    } else {
      setRetentionPeriod(value);
    }
  };

  return (
    <div className="p-[40px] border border-solid border-[#DDDDDD] rounded-[8px]">
      <p className="text-[22px] text-[#333333] font-[500]">
        {t('dataManagement.uploadManagement.retentionPeriod.title')}
      </p>
      <p className="mt-[8px] text-[14px] text-[#AAAAAA] font-[500] whitespace-pre-wrap">
        {t('dataManagement.uploadManagement.retentionPeriod.description')}
      </p>
      <div className="flex flex-wrap items-center mt-[24px] py-[16px] gap-[8px]">
        <p className="text-[16px] text-[#111111] font-[500] min-w-[240px]">
          {t('dataManagement.uploadManagement.retentionPeriod.period.title')}
        </p>
        <select
          className="ml-[24px] px-[8px] h-[40px] rounded-[8px] min-w-[90px]"
          defaultValue={defaultPeriod}
          onChange={(e) => onSelectPeriod(e.target.value)}
        >
          <option value={30}>30</option>
          <option value={90}>90</option>
          <option value={180}>180</option>
          <option value={selfInputOptionKey}>
            {t('dataManagement.setNotification.standard.selfInput')}
          </option>
        </select>
        {isCustomInputVisible && (
          <div className="flex items-center px-[8px] w-[60px] h-[40px] border border-solid border-[#D8D8D8] rounded-[8px]">
            <input
              className="w-full"
              value={retentionPeriod}
              type="number"
              onChange={(e) => onChangePeriod(Number(e.target.value))}
            />
          </div>
        )}
        <span className="text-[14px] text-[#333333] font-[400]">
          {t('dataManagement.uploadManagement.retentionPeriod.period.days')}
        </span>
      </div>
      <div className="mt-[24px] py-[16px] flex items-center justify-center gap-[8px]">
        <Button
          title={t('dialog.cancel')}
          colorset="minor"
          shape="rect"
          styleType="line"
          size="medium"
        />
        <Button
          title={t('dialog.save')}
          colorset="major"
          shape="rect"
          styleType="solid"
          size="medium"
        />
      </div>
    </div>
  );
}
