import * as RadixPrimitives from '@radix-ui/react-toast';
import { PropsWithChildren } from 'react';
import { useToastContext } from './Provider';

import { clsx } from 'clsx';
import { useToastStatusContext } from './Container';

export interface Props extends RadixPrimitives.ToastProps {
  open?: boolean;
  onOpenChange?(open: boolean): void;
}

const animationClasses = {
  right: 'animate-slide-in-right',
  left: 'animate-slide-in-left',
  up: 'animate-slide-down',
  down: 'animate-slide-up',
};

export function Root({
  children,
  className = '',
  ...props
}: PropsWithChildren<Props>) {
  const {
    toastContext: { direction },
  } = useToastContext();

  const { open, setOpen } = useToastStatusContext();

  return (
    <RadixPrimitives.Root
      open={open}
      onOpenChange={setOpen}
      data-direction={direction}
      className={clsx(`ToastRoot ${animationClasses[direction]}`, className)}
      {...props}
    >
      {children}
    </RadixPrimitives.Root>
  );
}

export default Root;
