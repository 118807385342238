import * as RadixPrimivites from '@radix-ui/react-toast';
import { clsx } from 'clsx';
import { useToastContext } from './Provider';
import { Portal } from '@radix-ui/react-portal';
import { Fragment } from 'react/jsx-runtime';

function Viewport({
  className,
  children,
  ...props
}: RadixPrimivites.ToastViewportProps) {
  const {
    toastContext: { direction, usePortal },
  } = useToastContext();

  const positionClasses = {
    right: 'fixed bottom-4 right-16',
    left: 'fixed bottom-4 left-28',
    up: 'fixed top-28 left-1/2  transform -translate-x-1/2',
    down: 'fixed bottom-4 left-1/2 transform -translate-x-1/2',
  };
  const Comp = usePortal ? Portal : Fragment;

  return (
    <Comp>
      <RadixPrimivites.Viewport
        {...props}
        className={clsx(
          className,

          `${positionClasses[direction]} flex flex-col gap-2 z-[1000]`,
        )}
      >
        {children}
      </RadixPrimivites.Viewport>
    </Comp>
  );
}

export default Viewport;
