import { Button } from '@daouoffice/ui';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { selectedAppIdAtom } from '../../../../../../../components/GlobalConfig/store/selectedAppIdAtom';

export function Header() {
  const { t } = useTranslation();
  const [, setSelectedAppId] = useAtom(selectedAppIdAtom);
  // TODO: 고객포털 계약자, 주문 담당자 등록된 데이터 관리자인지 확인하는 API 존재하는지.
  const isCustomerPortalManager = true;

  // 용량 사용 현황 버튼 클릭 시, 용량관리 > 용량 사용 현황으로 화면 전환
  const onClickStorageUsageDetailHandler = () => setSelectedAppId('19701');

  // 고객포털 계약자, 주문 담당자 등록된 데이터 관리자인지 확인 용량 구매하기 버튼 클릭 시, 고객포털 주문 페이지로 연결.
  const onClickStorageBuyStorage = () => {
    alert('고객포털 주문 페이지로 연결');
  };

  return (
    <div className="flex justify-between">
      <h2 className="font-[600] -tracking-[0.88px] text-[22px] text-[#333333]">
        {t('globalconfig.data.member.info')}
      </h2>
      <div className="flex gap-2">
        <Button
          title=""
          withTitle={false}
          styleType="line"
          className="flex items-center px-3 py-2 !border-solid !border !rounded-lg gap-1 !border-[#E6E6E6]"
          onClick={onClickStorageUsageDetailHandler}
        >
          <span>{t('globalconfig.data.member.storageusage.detail')}</span>
          <ChevronRightIcon className="size-4 stroke-[#B3B3B3]" />
        </Button>
        {isCustomerPortalManager && (
          <Button
            title=""
            withTitle={false}
            styleType="line"
            className="flex items-center px-3 py-2 !border-solid !border !rounded-lg gap-1 !border-[#E6E6E6]"
            onClick={onClickStorageBuyStorage}
          >
            <span>{t('globalconfig.data.member.storageusage.buy')}</span>
            <ChevronRightIcon className="size-4 stroke-[#B3B3B3]" />
          </Button>
        )}
      </div>
    </div>
  );
}

export default Header;
