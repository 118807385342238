import { useSuspenseQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as memberInfoQuery from '../../api/memberInfoQuery';
import { quotaAtom } from '../../store';
import { formatBytes } from '../../utils/byteFormatter';
import { Header } from './Header';
import { InfoCard } from './InfoCard';

export function MemberInfoCard() {
  const [quota, setQuota] = useAtom(quotaAtom);
  const { t } = useTranslation();
  const { data: res, error } = useSuspenseQuery({
    queryKey: [memberInfoQuery.QUERY_KEY],
    queryFn: async () => await memberInfoQuery.memberInfoQuery(),
  });

  if (error) {
    <>{error}</>;
  }

  useEffect(() => {
    setQuota(res?.data?.companyDto ?? {});
  }, [res, setQuota]);

  return (
    <>
      <Header />
      <div className="grid grid-flow-col justify-stretch mt-5 gap-6">
        <InfoCard title={t('globalconfig.data.member.current.count')}>
          <div className="flex gap-1 items-baseline">
            <span className="text-[#333] text-[36px] font-medium -tracking-[0.72px]">
              {res?.data?.userCountInfoDto?.normalUserCount ?? 0}
            </span>
            <span className="text-[14px] text-[#333] font-medium -tracking-[0.28px]">
              {t('명')}
            </span>
          </div>
          <span className="text-[14px] font-normal -tracking-[0.28px] text-[#888888]">
            {t('globalconfig.data.member.current.detail')
              .replace(
                '{normalUserCount}',
                `${res?.data?.userCountInfoDto?.normalUserCount ?? 0}`,
              )
              .replace(
                '{dormantUserCount}',
                `${res?.data?.userCountInfoDto?.dormantUserCount ?? 0}`,
              )}
          </span>
        </InfoCard>
        <InfoCard title={t('globalconfig.data.member.stopped.count')}>
          <div className="flex gap-1 items-baseline">
            <span className="text-[#333] text-[36px] font-medium -tracking-[0.72px]">
              {res?.data?.userCountInfoDto?.stopUserCount ?? 0}
            </span>
            <span className="text-[14px] text-[#333] font-medium -tracking-[0.28px]">
              {t('globalconfig.data.member.count')}
            </span>
          </div>
        </InfoCard>
        <InfoCard title={t('globalconfig.data.member.storageusage.available')}>
          <div className="flex gap-1 items-baseline">
            <span className="text-[#333] text-[36px] font-medium -tracking-[0.72px]">
              {formatBytes(quota?.availableQuota ?? 0)}
            </span>
            <span className="text-[14px] text-[#333] font-[400] -tracking-[0.28px]">
              {` / ${formatBytes(quota?.allocatedQuota ?? 0)}`}
            </span>
          </div>
        </InfoCard>
      </div>
    </>
  );
}
