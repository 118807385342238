import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { columnDefGenerator } from '@daouoffice/ui/lib/labs/Table';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { MemberDataInfo } from '../../types';
import { useActionsContext } from './Provider';

export interface Props {
  contents: MemberDataInfo[];
}

export function MemberDataInfoTable({ contents }: Props) {
  const { t } = useTranslation();
  const { setSelectedRows } = useActionsContext();

  const columnDefs: ColumnDef<MemberDataInfo>[] = [
    columnDefGenerator<MemberDataInfo>(
      'userName',
      t('globalconfig.data.member.field.name'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'userEmail',
      t('globalconfig.data.member.field.address'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'position',
      t('globalconfig.data.member.field.position'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'mail',
      t('globalconfig.data.member.field.mailstorage'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'webFolder',
      t('globalconfig.data.member.field.folderstorage'),
    ),
  ];

  return contents.length === 0 ? (
    <div className="flex items-center justify-center border rounded-lg w-full h-[256px] text-lg">
      {t('globalconfig.table.data.null')}
    </div>
  ) : (
    <TablePrimitives.Root
      selectable={true}
      contents={contents}
      columnDefs={columnDefs}
      onSelectContents={setSelectedRows}
    >
      <TablePrimitives.Contents />
    </TablePrimitives.Root>
  );
}

export default MemberDataInfoTable;
