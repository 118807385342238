import React, { CSSProperties } from 'react';
import { Provider } from 'jotai';

import App from './OrgChart';
import store from './Store';
import { Provider as OrgChartProvider } from './Provider';
import { NodeProps } from './interface';
import { generateUUID } from '../../lib/utils/util';

export interface Props {
  style?: CSSProperties;
  onNodeClick?: (node: NodeProps) => void;
  useCheckbox?: boolean;
}

const uuid = generateUUID();

export default function OrgChart({
  style,
  useCheckbox = true,
  onNodeClick,
}: Props) {
  return (
    <Provider store={store}>
      <OrgChartProvider
        onNodeClick={onNodeClick}
        useCheckbox={useCheckbox}
        uuid={uuid}
      >
        <App style={style}></App>
      </OrgChartProvider>
    </Provider>
  );
}
