import { clsx } from 'clsx';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, IconButton } from '@daouoffice/ui/lib/foundation/Button';
import { CloseIcon } from '@daouoffice/ui/lib/icons/dop/24/Close';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { useToast } from '../../../../Toast';
import * as detailInfoApi from './apis/detailInfo';
import * as requestHandlerApi from './apis/requestHandling';
import { QUERY_KEY as LIST_QUERY_KEY } from './apis/getRequestList';
import { COLOR_COMPLETE, COLOR_EXPECTED, COLOR_REJECT } from './colors';

import dialogAnimationStyles from './styles/dialogAnimation.module.css';

export interface Props {
  infoId: number;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function DetailInfoDialog({ infoId, open, onOpenChange }: Props) {
  const { t } = useTranslation();
  const { data: detailInfo, error } = useQuery({
    queryKey: [detailInfoApi.QUERY_KEY, infoId],
    queryFn: async () => detailInfoApi.getDetailInfo(infoId.toString()),
  });

  if (error) {
    console.error(
      `Error: GlobalConfig > DataManagement > DetailInfoDialog : ${error.message}`,
    );
  }

  const encoder = useMemo(() => new TextEncoder(), []);

  const [comment, setComment] = useState(
    t('dataManagement.requestManagement.dialog.defaultComment'),
  );

  const [commentSize, setCommentSize] = useState(() => {
    const byteArray = encoder.encode(comment);
    return byteArray.length;
  });

  const [appliedSize, setAppliedSize] = useState<undefined | number>(undefined);

  const onChangeComment = (comment: string) => {
    const byteArray = encoder.encode(comment);
    if (byteArray.length > 100) return;
    setCommentSize(byteArray.length);
    setComment(comment);
  };

  const queryClient = useQueryClient();
  const toaster = useToast();

  const rejectMutation = useMutation({
    mutationFn: async () =>
      requestHandlerApi.rejectRequest(infoId.toString(), comment, detailInfo),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [detailInfoApi.QUERY_KEY, infoId, LIST_QUERY_KEY],
      });
      toaster.info(t('dataManagement.requestManagement.dialog.rejected'));
    },
    onError: (error) => {
      console.error(
        `Error: GlobalConfig > DataManagement > DetailInfoDialog > rejectMutation : ${error.message}`,
      );
    },
  });

  const approveMutation = useMutation({
    mutationFn: async () =>
      requestHandlerApi.approveRequest(
        infoId.toString(),
        comment,
        appliedSize ?? 0,
        detailInfo,
      ),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [detailInfoApi.QUERY_KEY, infoId, LIST_QUERY_KEY],
      });
      toaster.info(t('dataManagement.requestManagement.dialog.rejected'));
    },
    onError: (error) => {
      console.error(
        `Error: GlobalConfig > DataManagement > DetailInfoDialog > approveMutation : ${error.message}`,
      );
    },
  });

  const infoStatus =
    detailInfo?.detailApplyState === 'EXPECTED'
      ? t('dataManagement.requestManagement.status.expected')
      : detailInfo?.detailApplyState === 'COMPLETE'
        ? t('dataManagement.requestManagement.status.completed')
        : t('dataManagement.requestManagement.status.rejected');

  const statusColor =
    detailInfo?.detailApplyState === 'EXPECTED'
      ? COLOR_EXPECTED
      : detailInfo?.detailApplyState === 'COMPLETE'
        ? COLOR_COMPLETE
        : COLOR_REJECT;

  const requestType =
    detailInfo?.volumeType === 'MAIL'
      ? t('dataManagement.requestManagement.type.mail')
      : t('dataManagement.requestManagement.type.webstorage');

  const usageStatus = detailInfo?.userUsage.split('/');

  const minimumSize = 0;
  const maximumSize = detailInfo?.volumeType === 'MAIL' ? 122800 : 10240;

  const onChangeAppliedSize = (size: number) => {
    if (size < minimumSize) setAppliedSize(minimumSize);
    else if (size > maximumSize) setAppliedSize(maximumSize);
    else setAppliedSize(size);
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay
          className={clsx(
            'fixed inset-0 bg-black opacity-[0.5] z-[1000] transition-opacity ease-linear',
            dialogAnimationStyles.OverlayAnimation,
          )}
        />
        <Dialog.Content
          className={clsx(
            'fixed -translate-x-1/2 -translate-y-1/2 flex flex-col w-[580px] p-[40px] bg-white rounded-[16px] top-[50%] left-[50%] z-[1000]',
            dialogAnimationStyles.ContentAnimation,
          )}
        >
          <Dialog.Close asChild>
            <IconButton
              className="absolute size-[40px] top-[8px] right-[4px]"
              title={t('dialog.close')}
            >
              <CloseIcon />
            </IconButton>
          </Dialog.Close>
          <div className="flex items-center text-[#1A1A1A] text-[26px] font-[500]">
            <div
              className="mr-[8px] h-[24px] px-[6px] py-[2px] text-white text-[13px] font-[500] rounded-[8px]"
              style={{ backgroundColor: statusColor }}
            >
              {infoStatus}
            </div>
            {t('dataManagement.requestManagement.dialog.title')}
          </div>
          <TitleAndContent
            className="mt-[32px]"
            title={t('dataManagement.requestManagement.dialog.requestor')}
            content={detailInfo?.name ?? ''}
          />
          <TitleAndContent
            className="mt-[4px]"
            title={t('dataManagement.requestManagement.dialog.requestType')}
            content={requestType}
          />
          <TitleAndContent
            className="mt-[4px]"
            title={t('dataManagement.requestManagement.dialog.requestDate')}
            content={detailInfo?.requestStartDate ?? ''}
          />
          <div className="flex mt-[10px] text-[#363636] text-[14px] font-[400]">
            <span className="text-[#8D8D8D] text-[13px] font-[500] w-[80px] mr-[8px]">
              {t('dataManagement.requestManagement.dialog.usage')}
            </span>
            <div>
              <p>
                {t('dataManagement.requestManagement.dialog.atRequestDate')}
                {usageStatus && usageStatus[0]}
              </p>
              <p className="text-[#888888]">{usageStatus && usageStatus[1]}</p>
            </div>
          </div>
          <div className="flex mt-[10px] text-[#363636] text-[14px] font-[400]">
            <span className="text-[#8D8D8D] text-[13px] font-[500] w-[80px] mr-[8px]">
              {t('dataManagement.requestManagement.dialog.requestComment')}
            </span>
            <div className="whitespace-pre-wrap">{detailInfo?.userComment}</div>
          </div>
          <div className="w-full h-[1px] bg-[#F3F3F3] my-[16px]" />
          <div className="flex text-[#1A1A1A] text-[14px] font-[500]">
            <p className="inline-block w-[94px] flex-shrink-0">
              {t('dataManagement.requestManagement.dialog.availableStorage')}
            </p>
            <span className="text-[#3A87FD] text-[14px] font-[600] w-[80px] ml-[16px]">
              {detailInfo && detailInfo.siteUsage.free}
            </span>
          </div>
          <div className="flex items-center mt-[16px] text-[#1A1A1A] text-[14px] font-[500] h-[40px]">
            <p className="inline-block w-[94px] flex-shrink-0">
              {t('dataManagement.requestManagement.dialog.increaseStorage')}
            </p>
            <div className="flex items-center size-full ml-[16px] px-[12px] border border-solid border-[#AAAAAA] rounded-[8px]">
              <input
                className="h-full flex-grow bg-transparent border-none focus:outline-none"
                placeholder={`${minimumSize}~${maximumSize}의 숫자만 입력 가능합니다.`}
                type="number"
                value={appliedSize}
                min={minimumSize}
                max={maximumSize}
                onChange={(e) => onChangeAppliedSize(Number(e.target.value))}
              />
              <span>MB</span>
            </div>
          </div>
          <div className="flex mt-[16px] text-[#1A1A1A] text-[14px] font-[500]">
            <p className="inline-block w-[94px] flex-shrink-0">코멘트</p>
            <div className="w-full h-[96px] ml-[16px] pl-[12px] border border-solid border-[#AAAAAA] rounded-[8px]">
              <textarea
                className="size-full pt-[8px] bg-transparent resize-none border-none focus:outline-none"
                placeholder={t(
                  'dataManagement.requestManagement.dialog.pleaseInputContent',
                )}
                value={comment}
                onChange={(e) => onChangeComment(e.target.value)}
              />
            </div>
          </div>
          <p className="w-full mt-[4px] text-end text-[#AAAAAA] text-[12px]">
            {commentSize}/100byte
          </p>
          <div className="flex justify-end mt-[40px] gap-[16px]">
            {detailInfo?.detailApplyState !== 'COMPLETE' ? (
              <>
                <Button
                  title={t('dataManagement.requestManagement.status.rejected')}
                  size="medium"
                  colorset="minor"
                  shape="rect"
                  styleType="ghost"
                  onClick={() => rejectMutation.mutate()}
                />
                <Button
                  title={t(
                    'dataManagement.requestManagement.dialog.increaseStorage',
                  )}
                  size="medium"
                  colorset="major"
                  shape="rect"
                  styleType="solid"
                  onClick={() => approveMutation.mutate()}
                />
              </>
            ) : (
              <Dialog.Close asChild>
                <Button
                  title={t('dialog.confirm')}
                  size="medium"
                  colorset="major"
                  shape="rect"
                  styleType="solid"
                />
              </Dialog.Close>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

function TitleAndContent({
  className,
  title,
  content,
}: {
  className?: string;
  title: string;
  content: string;
}) {
  return (
    <div
      className={clsx(
        'flex items-center text-[#363636] text-[14px] font-[400] h-[32px]',
        className,
      )}
    >
      <span className="text-[#8D8D8D] text-[13px] font-[500] w-[80px] mr-[8px]">
        {title}
      </span>
      {content}
    </div>
  );
}
