import { IconButton } from '@daouoffice/ui';
import {
  ChevronRightIcon,
  ClipboardDocumentListIcon,
  ListBulletIcon,
  PencilSquareIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { AppInfo, AppOverView } from '../../../../types';
import { useContext } from '../../../Provider';

export interface Props {
  appList: AppOverView[];
}

export function AppList({ appList }: Props) {
  const { t } = useTranslation();
  const { setSelectedAppType } = useContext();

  const getApp = (app: AppOverView): AppInfo => {
    if (app.appCode === '@DOP-EMPLOYEE/APPROVAL') {
      return {
        name: t('globalconfig.data.app.approval.title'),
        type: 'approval',
        icon: <PencilSquareIcon className="h-6 w-6" key="approval" />,
        summary: t('globalconfig.data.app.approval.summary')
          .replace('{deptCount}', `${app.rowCount}`)
          .replace('{approvalListCount}', `${app.attachCount}`),
      };
    } else if (app.appCode === '@DOP-EMPLOYEE/BOARD') {
      return {
        name: t('globalconfig.data.app.board.title'),
        type: 'board',
        icon: <ListBulletIcon className="h-6 w-6" key="board" />,
        summary: t('globalconfig.data.app.board.summary').replace(
          '{deptCount}',
          `${app.rowCount}`,
        ),
      };
    } else if (app.appCode === '@DOP-EMPLOYEE/REPORT') {
      return {
        name: t('globalconfig.data.app.report.title'),
        type: 'report',
        icon: <ClipboardDocumentListIcon className="h-6 w-6" key="report" />,
        summary: t('globalconfig.data.app.report.summary').replace(
          '{deptCount}',
          `${app.rowCount}`,
        ),
      };
    } else {
      return {
        name: t('globalconfig.data.app.group.title'),
        type: 'group',
        icon: <UserIcon className="h-6 w-6" key="group" />,
        summary: t('globalconfig.data.app.group.summary')
          .replace('{deptCount}', `${app.rowCount}`)
          .replace('{groupList}', `${app.attachCount}`),
      };
    }
  };

  const appInfoList = appList?.map((app) => getApp(app));

  return (
    <>
      {appInfoList?.map((info, index) => (
        <>
          <div
            className="flex items-center justify-between py-7"
            key={info.name}
          >
            <div className="flex items-center">
              {info.icon}
              <div className="grid ms-6 gap-[5.5px]">
                <span className="font-medium text-base -tracking-[0.28px]">
                  {info.name}
                </span>
                <span className="font-normal text-sm -tracking-[0.28px]">
                  {info.summary}
                </span>
              </div>
            </div>
            <IconButton
              className="relative right-0"
              title="btn_icon"
              onClick={() => setSelectedAppType(info.type)}
            >
              <ChevronRightIcon className="h-6 w-6" />
            </IconButton>
          </div>
          {appInfoList.length - 1 !== index && (
            <hr className="border-[#E6E7EA] border !m-0 !ms-12" />
          )}
        </>
      ))}
    </>
  );
}

export default AppList;
