const KILOBYTE = 1024;
const MEGABYTE = KILOBYTE * 1024;
const GIGABYTE = MEGABYTE * 1024;

export const byteToKilobyte = (byte: number): number => byte / KILOBYTE;
export const kiltobyteToByte = (kilobyte: number): number =>
  kilobyte * KILOBYTE;
export const byteToKilobyteFixedString = (
  byte: number,
  fixedTo: number = 1,
): string => byteToKilobyte(byte).toFixed(fixedTo) + 'KB';
export const byteToMegabyte = (byte: number): number => byte / MEGABYTE;
export const megabyteToByte = (megabyte: number): number => megabyte * MEGABYTE;
export const byteToMegabyteFixedString = (
  byte: number,
  fixedTo: number = 1,
): string => byteToMegabyte(byte).toFixed(fixedTo) + 'MB';
export const byteToGigabyte = (byte: number): number => byte / GIGABYTE;
export const gigabyteToByte = (gigabyte: number): number => gigabyte * GIGABYTE;
export const byteToGigabyteFixedString = (
  byte: number,
  fixedTo: number = 1,
): string => byteToGigabyte(byte).toFixed(fixedTo) + 'GB';
