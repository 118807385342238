'use client';

import * as React from 'react';
import type { LoginUserData } from '../types';

export interface AuthContextValues {
  readonly me: LoginUserData | null;
  isLogin: () => boolean;
  logout: () => void;
}

export const AuthContext = React.createContext<AuthContextValues>({
  me: null,
  isLogin: () => false,
  logout: () => undefined,
});

export function useAuthContext() {
  return React.useContext(AuthContext);
}
