import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { Button } from '@daouoffice/ui/lib/foundation/Button';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import * as fileListApi from './apis/fileList';
import { FileInfo } from './types';
import { useState } from 'react';

export function FileList() {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageOffet, setCurrentPageOffset] = useState(20);

  const { data: fileData, error } = useQuery({
    queryKey: [fileListApi.QUERY_KEY, currentPage, pageOffet],
    queryFn: fileListApi.getFileList,
  });

  if (error) {
    console.error(
      'Error: GlobalConfig > DataManagement > UploadDataManagement : ',
      error,
    );
  }

  const dateTitles = {
    today: t('dataManagement.requestManagement.date.today'),
    days7: t('dataManagement.requestManagement.date.7daysAgo'),
    days30: t('dataManagement.requestManagement.date.30daysAgo'),
    days90: t('dataManagement.requestManagement.date.90daysAgo'),
    selfInput: t('dataManagement.requestManagement.date.selfInput'),
  };

  const onKeywordChange = (keyword: string) => {
    console.log('keyword', keyword);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page - 1);
  };

  const onClickLastPage = () => {
    setCurrentPage(fileData?.page.totalPage ? fileData.page.totalPage - 1 : 0);
  };

  const onOffsetChange = (offset: number) => {
    setCurrentPageOffset(offset);
  };

  const columnDefs: ColumnDef<FileInfo>[] = [
    {
      id: 'fileName',
      accessorKey: 'fileName',
      header: () => (
        <div className="max-w-[480px]">
          {t('dataManagement.uploadManagement.retentionPeriod.list.fileSize')}
        </div>
      ),
      cell: (info) => {
        const fileName = info.getValue() as string;
        return (
          <p className="max-w-[510px] h-[48px] text-ellipsis">{fileName}</p>
        );
      },
      maxSize: 510,
    },
    {
      id: 'fileSize',
      accessorKey: 'fileSize',
      header: t(
        'dataManagement.uploadManagement.retentionPeriod.list.fileSize',
      ),
    },
    {
      id: 'createdDate',
      accessorKey: 'createdDate',
      header: t(
        'dataManagement.uploadManagement.retentionPeriod.list.createDate',
      ),
    },
    {
      id: 'senderName',
      accessorKey: 'senderName',
      header: t('dataManagement.uploadManagement.retentionPeriod.list.sender'),
    },
  ];

  return (
    <div className="mt-[32px] p-[40px] border border-solid border-[#DDDDDD] rounded-[8px]">
      <p className="text-[22px] text-[#333333] font-[500]">
        {t('dataManagement.uploadManagement.retentionPeriod.list.title')}
      </p>
      <p className="mt-[12px] text-[14px] text-[#888888] font-[400]">
        <span className="h-[20px] px-[8px] mr-[8px] rounded-[8px] bg-[#FFEEEE] text-[#FF5252]">
          notice
        </span>
        {t('dataManagement.uploadManagement.retentionPeriod.list.description')}
      </p>
      <TablePrimitives.Root<FileInfo>
        className="mt-[32px]"
        contents={fileData?.data || []}
        columnDefs={columnDefs}
        selectable
      >
        <div className="flex flex-wrap items-center w-full">
          <TablePrimitives.SearchBar
            className="inline-block w-[240px]"
            onKeywordChange={onKeywordChange}
          />
          <div className="mx-[8px] w-[1px] h-[12px] bg-[#C5CBD3]" />
          <TablePrimitives.Filter.Container className="inline-block">
            <TablePrimitives.Filter.DateOption
              title={t('dataManagement.requestManagement.list.applyDate')}
              filterKey="applyDate"
              dateOptionTitles={dateTitles}
            />
          </TablePrimitives.Filter.Container>
          <div className="spacer flex-grow" />
          <Button
            title={t(
              'dataManagement.uploadManagement.retentionPeriod.list.delete',
            )}
            size="medium"
            shape="rect"
            colorset="minor"
            styleType="line"
          ></Button>
        </div>
        <TablePrimitives.Contents className="pb-[32px] mt-[16px] mb-[32px]" />
        <TablePrimitives.Pagination
          className="mt-[16px]"
          currentPage={fileData?.page.pageNumber ?? 1}
          totalPage={fileData?.page.totalPage ?? 1}
          onNextPage={onPageChange}
          onPreviousPage={onPageChange}
          onClickPage={onPageChange}
          onFirstPage={() => onPageChange(1)}
          onLastPage={onClickLastPage}
        />
        <TablePrimitives.SizeSelector
          onChangeTableSize={onOffsetChange}
        ></TablePrimitives.SizeSelector>
      </TablePrimitives.Root>
    </div>
  );
}
