'use client';

// import { useSuspenseQuery } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useAtom } from 'jotai';
import { CSSProperties, useEffect, useState } from 'react';

import { IconButton } from '@daouoffice/ui/lib/foundation/Button/IconButton';
import { useDebounce } from '@daouoffice/ui/lib/hooks';
import { ClearIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { useTranslation } from '../../lib/i18n/client/useTranslation';

import NodeList from './NodeList';
import SearchResult from './SearchResult';
import SelectedNodes from './SelectedNodes';
import { treesAtom } from './Store';
import { getOrg, searchDepartments, searchUsers } from './getOrg';
import { NodeProps, PageResponseProps } from './interface';
import './organization.css';

export interface Props {
  style?: CSSProperties;
  memberNodeSelectHandler?: (userId: number) => void;
}

export function OrgChart({ style }: Props) {
  const { t } = useTranslation();

  const [trees, setTrees] = useAtom(treesAtom);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [departments, setDepartments] = useState<NodeProps[]>([]);
  const [users, setUsers] = useState<NodeProps[]>([]);
  const [page, setPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    getOrg()
      .then((orgData) => {
        console.log(orgData);
        setTrees(orgData);
        changeRandomPresences(); // TODO. sample code
      })
      .catch((e) => console.log(e));
  }, []);

  const changeRandomPresences = () => {
    // setInterval(() => {
    //   let presences = [];
    //   for (let userId = 0; userId < 100; userId++) {
    //     presences.push({ userId, status: parseInt(Math.random() * 2) });
    //   }
    //   setPresences(presences);
    //   // console.log(presences);
    // }, 2000);
  };

  const clearSerch = () => {
    setDepartments([]);
    setUsers([]);
    setPage(0);
    setKeyword('');
    setIsLastPage(false);
  };

  const handleSearch = async (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const currentKeyword: string = target.value;
    const hasKeyword = !!currentKeyword;
    if (isSearchMode !== hasKeyword) setIsSearchMode(hasKeyword);
    if (!currentKeyword) {
      clearSerch();
      return;
    }
    setIsSearching(true);
    const departments: Array<NodeProps> =
      await searchDepartments(currentKeyword);
    const users: PageResponseProps = (await searchUsers(
      currentKeyword,
    )) as PageResponseProps;
    setIsSearching(false);
    setDepartments(departments);
    setUsers(users.data.elements);
    setIsLastPage(users.isLastPage);
    setKeyword(currentKeyword);
  };

  const debouncedHandleSearch = useDebounce(
    (e: React.FormEvent<HTMLInputElement>) => {
      handleSearch(e).catch((e) => console.log(e));
    },
    250,
  );

  // const handlememberNodeSelectHandler = (id: number) =>
  //   memberNodeSelectHandler && memberNodeSelectHandler(id);

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    const el = e.target as HTMLInputElement;
    const isScrollBottom =
      el.scrollHeight - Math.ceil(el.scrollTop) - el.clientHeight < 1;
    if (!isSearchMode || isLastPage || !isScrollBottom) return;
    const nextPage = page + 1;
    const searchUsersResponse: PageResponseProps = (await searchUsers(
      keyword,
      nextPage,
    )) as PageResponseProps;
    setPage(nextPage);
    setUsers(users.concat(searchUsersResponse.data.elements));
    setIsLastPage(searchUsersResponse.isLastPage);
  };

  const debouncedHandleScroll = useDebounce(
    (e: React.UIEvent<HTMLInputElement>) => {
      handleScroll(e).catch((e) => console.log(e));
    },
    250,
  );

  return (
    <div
      className={clsx('dop_dialog dialog_resizable dop_organization')}
      style={style ?? {}}
    >
      <div className="wrap_popup">
        <SelectedNodes />
        <div className="wrap_search">
          <div className="group_input">
            <label className="label blind">{t('조직도 검색')}</label>
            <div className="input_search">
              <i className="icon ic_small">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.5596 4.5596C6.13496 2.98424 8.2716 2.09921 10.4995 2.09921C12.7274 2.09921 14.864 2.98424 16.4394 4.5596C18.0147 6.13496 18.8998 8.2716 18.8998 10.4995C18.8998 12.4253 18.2385 14.283 17.0416 15.7688L21.6364 20.3636C21.9879 20.7151 21.9879 21.2849 21.6364 21.6364C21.2849 21.9879 20.7151 21.9879 20.3636 21.6364L15.7688 17.0416C14.283 18.2385 12.4253 18.8998 10.4995 18.8998C8.2716 18.8998 6.13496 18.0147 4.5596 16.4394C2.98424 14.864 2.09921 12.7274 2.09921 10.4995C2.09921 8.2716 2.98424 6.13496 4.5596 4.5596ZM10.4995 3.89921C8.74899 3.89921 7.07018 4.5946 5.83239 5.83239C4.5946 7.07018 3.89921 8.74899 3.89921 10.4995C3.89921 12.25 4.5946 13.9288 5.83239 15.1666C7.07018 16.4044 8.74899 17.0998 10.4995 17.0998C12.25 17.0998 13.9288 16.4044 15.1666 15.1666C16.4044 13.9288 17.0998 12.25 17.0998 10.4995C17.0998 8.74899 16.4044 7.07018 15.1666 5.83239C13.9288 4.5946 12.25 3.89921 10.4995 3.89921Z"
                    fill="#AAAAAA"
                  ></path>
                </svg>
              </i>
              <input
                className="input_txt body_medium regular"
                type="search"
                placeholder={t('이름, 부서, 직위, 전화번호 검색')}
                onChange={debouncedHandleSearch}
              />
              <IconButton title="" className="clear">
                <ClearIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="container" onScroll={debouncedHandleScroll}>
          {!isSearchMode && <NodeList nodes={trees}></NodeList>}
          {isSearchMode && (
            <SearchResult
              departments={departments}
              users={users}
              isSearching={isSearching}
            ></SearchResult>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrgChart;
