import { createContext, PropsWithChildren, useContext } from 'react';
import { NodeProps } from './interface';

export interface OrgChartState {
  uuid: string;
  useCheckbox: boolean;
}

export interface OrgChartActions {
  onNodeClick?: (node: NodeProps) => void;
  memberNodeSelectHandler: (userId: number) => void;
}

export const OrgChartStateContext = createContext<OrgChartState>({
  uuid: '',
  useCheckbox: true,
});
export const OrgChartActionsContext = createContext<OrgChartActions>({
  onNodeClick: () => undefined,
  memberNodeSelectHandler: () => undefined,
});

export interface Props {
  uuid: string;
  onNodeClick?: (node: NodeProps) => void;
  useCheckbox: boolean;
  memberNodeSelectHandler?: (id: number) => void;
}

export function Provider({
  uuid,
  onNodeClick,
  useCheckbox,
  memberNodeSelectHandler = () => {},
  children,
}: PropsWithChildren<Props>) {
  const state = { useCheckbox, uuid };
  const actions = { memberNodeSelectHandler, onNodeClick };

  return (
    <OrgChartStateContext.Provider value={state}>
      <OrgChartActionsContext.Provider value={actions}>
        {children}
      </OrgChartActionsContext.Provider>
    </OrgChartStateContext.Provider>
  );
}

export const useOrgChartStateContext = () => useContext(OrgChartStateContext);
export const useOrgChartActionsContext = () =>
  useContext(OrgChartActionsContext);
export const useOrgChartContext = () => {
  const state = useOrgChartStateContext();
  const actions = useOrgChartActionsContext();

  return {
    state,
    actions,
  };
};
