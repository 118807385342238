import { fetch } from '../../../../../../lib/fetch/client';
import { DETAIL_DATA_USAGE_API } from '../../constants';
import { adaptStoragesResponse } from '../utils/stroageAdaptor';
import { BaseResponse } from '../../types';
import { StorageResponse, StorageGroup } from '../types';

export const DATA_USING_STORAGES_KEY =
  'QueryKeys.GlobalConfig.DataManagement.DataUsingStorages';

export async function getDataUsingStorages(): Promise<StorageGroup[]> {
  const response = await fetch(DETAIL_DATA_USAGE_API, {
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData = (await response.json()) as BaseResponse<{
    elements: StorageResponse[];
  }>;

  const empStorages = responseData.data.elements.filter(
    (strg) => strg.portalType === 'EMPLOYEE',
  );

  const bmpStorages = responseData.data.elements.filter(
    (strg) => strg.portalType === 'BUSINESS',
  );

  const commonStorages = responseData.data.elements.filter(
    (strg) => strg.portalType === 'NONE',
  );

  return [
    {
      portalType: 'NONE',
      count: commonStorages.reduce((acc, curr) => acc + curr.appCount, 0),
      apps: commonStorages.map(adaptStoragesResponse),
    },
    {
      portalType: 'EMPLOYEE',
      count: empStorages.reduce((acc, curr) => acc + curr.appCount, 0),
      apps: empStorages.map(adaptStoragesResponse),
    },
    {
      portalType: 'BUSINESS',
      count: bmpStorages.reduce((acc, curr) => acc + curr.appCount, 0),
      apps: bmpStorages.map(adaptStoragesResponse),
    },
  ];
}
