import { ContentsWrapper } from './components/ContentsWrapper';
import { MemberDataCard } from './components/MemberDataCard';
import { MemberInfoCard } from './components/MemberInfoCard';
import { Root } from './components/Root';

export function MemberDataManage() {
  return (
    <Root>
      <ContentsWrapper>
        <MemberInfoCard />
      </ContentsWrapper>
      <ContentsWrapper>
        <MemberDataCard />
      </ContentsWrapper>
    </Root>
  );
}

export default MemberDataManage;
