import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

export interface Props {
  deletedAt: string;
  storageUsage: string;
  className?: string;
}

export function DeptInfoCard({ deletedAt, storageUsage, className }: Props) {
  const { t } = useTranslation();

  return (
    <div className={clsx('bg-[#F9FAFB] rounded-lg px-5 py-4 gap-2', className)}>
      <div className="flex">
        <span className="w-1/5 text-sm text-[#aaaaaa]">
          {t('globalconfig.data.app.info.storage.usage')}
        </span>
        <span className="w-4/5 text-sm text-[#aaaaaa]">{storageUsage}</span>
      </div>
      <div className="flex mt-2">
        <span className="w-1/5 text-sm text-[#aaaaaa]">
          {t('globalconfig.data.app.info.deletedat')}
        </span>
        <span className="w-4/5 text-sm text-[#aaaaaa]">{deletedAt}</span>
      </div>
    </div>
  );
}

export default DeptInfoCard;
