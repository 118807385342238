import { byteToGigabyteFixedString } from '../../../../utils/byteConvertUtils';
import { Storage } from '../types';
import { NoSymbolIcon } from '@daouoffice/ui/lib/icons/heroicons/24/outline';

export interface Props {
  title: string;
  count: number;
  storages: Storage[];
}

export function StorageGroup({ title, count, storages }: Props) {
  return (
    <div className="mt-[40px]">
      <p className="text-[#888888] text-[16px] font-[500]">
        {title}
        <span className="text-[#00A1B9] text-[12px] ml-[4px] px-[4px] py-[2px] bg-[#D9F1F5] rounded-[8px]">
          {count}
        </span>
      </p>
      <div className="mt-[8px] pt-[12px] pb-[4px] flex flex-wrap gap-[16px]">
        {storages.map((storage) => (
          <StorageItem key={storage.id} {...storage} />
        ))}
      </div>
    </div>
  );
}

function StorageItem(storage: Storage) {
  const iconPropReturned = storage.icon ? (
    typeof storage.icon === 'function' ? (
      storage.icon()
    ) : (
      storage.icon
    )
  ) : (
    <NoSymbolIcon />
  );

  const iconPart =
    iconPropReturned &&
    (typeof iconPropReturned === 'string' ? (
      <span
        className="size-[20px]"
        dangerouslySetInnerHTML={{ __html: iconPropReturned }}
      />
    ) : (
      <span className="size-[20px]">{iconPropReturned}</span>
    ));

  return (
    <div key={storage.id} className="flex items-center w-[250px] flex-shrink-0">
      <div className="flex items-center justify-center p-[12px] border border-solid border-[#E6E7EA] rounded-[16px] size-[48px]">
        {iconPart}
      </div>
      <div>
        <p className="text-[#333333] text-[14px] font-[500] ml-[16px]">
          {storage.name}
        </p>
        <p className="text-[#888888] text-[13px] font-[400] ml-[16px]">
          {byteToGigabyteFixedString(storage.usage)}
        </p>
      </div>
    </div>
  );
}
