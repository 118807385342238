import { useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import * as deptEntireDataQuery from '../../../api/deptEntireDataQuery';
import { formatBytes } from '../../../utils/byteFormatter';
import { useContext } from '../../Provider';
import { AppList } from './AppList';
import { DeptInfoCard } from './DeptInfoCard';

export function DefaultContents() {
  const { t } = useTranslation();
  const { deptName, deptId } = useContext();
  const { data: res, error } = useSuspenseQuery({
    queryKey: [deptEntireDataQuery.QUERY_KEY],
    queryFn: async () => await deptEntireDataQuery.deptEntireDataQuery(deptId),
  });

  if (error) {
    <>{error}</>;
  }

  return (
    <>
      <div className="pt-6 px-2 h-full w-full">
        <h2 className="font-medium text-2xl">{deptName}</h2>
        <DeptInfoCard
          className="mt-6"
          createdAt={res?.data?.createdAt ?? t('globalconfig.none')}
          storageUsage={formatBytes(res?.data?.attachSize ?? 0)}
        />
        <div className="mt-10">
          <AppList appList={res?.data?.appList ?? []} />
        </div>
      </div>
    </>
  );
}
