/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useAtom } from 'jotai';
import { useState } from 'react';

import { IconButton } from '@daouoffice/ui/lib/foundation/Button/IconButton';
import { CheckBox } from '@daouoffice/ui/lib/foundation/CheckBox/CheckBox';
import { ChevronRightIcon } from '@daouoffice/ui/lib/icons/dop/24';

import { getDepartment } from './getOrg';
import { NodeProps } from './interface';
import NodeList from './NodeList';
import { useOrgChartContext } from './Provider';
import {
  addChilds,
  treesAtom,
  // toggleSelectSubNodes,
  updateTrees,
} from './Store';

export interface Props {
  node: NodeProps;
  isSearch?: boolean;
}

const DeptNode = ({ node, isSearch }: Props) => {
  const context = useOrgChartContext();
  const { onNodeClick } = context.actions;
  const { useCheckbox, uuid } = context.state;
  const [, setTrees] = useAtom(treesAtom);
  const nodes = node.childrenList || [];
  const [isOpend, setIsOpened] = useState(!!nodes.length);
  const [isLoaded, setIsLoaded] = useState(!!nodes.length);
  const isChecked = node.selected;
  const hasAdditional = isSearch && !!node.parentName;

  const toggleFold = async () => {
    setIsOpened(!isOpend);
    if (isLoaded) return;
    await getChildren();
  };

  const getChildren = async () => {
    if (isLoaded) return;
    setIsLoaded(true);
    const dept = (await getDepartment(node.id)) as NodeProps;
    if (dept.childrenList) {
      setTrees((trees) => addChilds(trees, dept));
    }
  };

  const toggleSelect = () => {
    setTrees((trees) =>
      updateTrees(trees, { ...node, selected: !node.selected }),
    );
  };

  const clickNode = () => {
    if (onNodeClick) onNodeClick(node);
  };

  return (
    <>
      <div className="subject" onKeyPress={() => {}} role="presentation">
        <IconButton
          title=""
          size="small"
          className="open"
          onClick={() => {
            toggleFold().catch((e) => console.log(e));
          }}
          aria-hidden="true"
        >
          <ChevronRightIcon rotate={isOpend ? 90 : 0} />
        </IconButton>
        <div className="info" onClick={clickNode}>
          <p className="body_medium semibold">{node.name}</p>
          {hasAdditional && <p className="additional">{node.parentName}</p>}
        </div>
      </div>
      <span style={{ position: 'absolute', top: '7px', right: '0px' }}>
        {useCheckbox && (
          <CheckBox
            id={`${node.nodeType}_${node.id}_${uuid}`}
            onChange={toggleSelect}
            defaultChecked={isChecked}
          ></CheckBox>
        )}
      </span>
      {isOpend && <NodeList nodes={nodes} className="sub"></NodeList>}
    </>
  );
};

export default DeptNode;
