import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { CellContext } from '@tanstack/react-table';

export function columnDefGenerator<T>(
  key: string,
  title: string,
  withFilter?: boolean,
  // TODO : 필터 기능 추가 필요.
  // onFilterChanged?: (filterValue: string) => void,
) {
  return {
    accessorKey: key,
    id: key,
    header: () => (
      <div className="flex items-center py-3 text-left gap-1">
        {title}
        {withFilter && <ChevronDownIcon className="w-6 h-6" />}
      </div>
    ),
    cell: (value: CellContext<T, unknown>) => (
      <div className="py-3 text-left">{value.renderValue() as string}</div>
    ),
  };
}
