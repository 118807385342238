import { useState } from 'react';
import { clsx } from 'clsx';
import { IconButton } from '../../../foundation/Button';
import { useDebounce } from '../../../hooks';
import { SearchIcon } from '../../../icons/dop/24';

export interface Props {
  className?: string;
  keyword?: string;
  onKeywordChange: (keyword: string) => void;
}

export function SearchBar({
  className,
  keyword: defaultKeyword,
  onKeywordChange,
}: Props) {
  const [keyword, setKeyword] = useState<string>(defaultKeyword ?? '');

  const debouncedHandleSearch = useDebounce((value: string) => {
    setKeyword(value);
    onKeywordChange(value);
  }, 250);

  const onChangeHandler = (value: string) => {
    setKeyword(value);
    debouncedHandleSearch(value);
  };

  return (
    <div
      className={clsx(
        'flex border border-gray-300 rounded-[8px] py-[3px] px-[6px]',
        className,
      )}
    >
      <IconButton title="btn_search" size="small">
        <SearchIcon className="fill-[#E5E5E5]" />
      </IconButton>
      <input
        className="flex-grow ps-2 focus:outline-none"
        onChange={(e) => onChangeHandler(e.target.value)}
        placeholder={'검색어를 입력해주세요.'}
        value={keyword}
      />
    </div>
  );
}

export default SearchBar;
