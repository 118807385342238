import { clsx } from 'clsx';
import { useQuery } from '@tanstack/react-query';
import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
} from '@daouoffice/ui/lib/icons/heroicons/24/outline';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { byteToGigabyteFixedString } from '../../../utils/byteConvertUtils';
import {
  COMPANY_DATA_USAGE_KEY,
  getCompanyDataStatus,
} from './apis/getCompanyDataStatus';

export function PresentCondition() {
  const { data, error } = useQuery({
    queryKey: [COMPANY_DATA_USAGE_KEY],
    queryFn: getCompanyDataStatus,
  });

  const { t } = useTranslation();

  if (error) {
    console.error(`Error: GlobalConfig > PresentCondition : ${error.message}`);
  }

  const colorCodes = ['#FF7575', '#F99B38', '#F5D428', '#B5C0D0'];

  const TitleAndVolume = ({
    title,
    volume,
  }: {
    title: string;
    volume: number;
  }) => (
    <>
      {title + '   '}
      <span className="text-[#333333]">
        {byteToGigabyteFixedString(volume ?? 0)}
      </span>
    </>
  );

  const DataField = ({
    width,
    backgroundColor,
  }: {
    key: string;
    width: string;
    backgroundColor: string;
  }) => (
    <div
      className="flex-shrink-0"
      style={{
        width: `${width}%`,
        backgroundColor: backgroundColor,
      }}
    />
  );

  return (
    <div className="relative flex-grow p-[40px] border border-solid border-[#E6E7EA] rounded-[8px] text-[#333333]">
      <button
        className="hidden absolute p-[6px] top-[34px] right-[40px] border border-solid border-[#E6E7EA] rounded-[8px]"
        title="refresh"
      >
        <ArrowPathIcon className="size-[20px]" />
      </button>
      <p className="text-[18px] font-[600]">
        {t('dataManagement.currentUsage.currentUsage')}
      </p>
      <p className="mt-[20px] text-[36px] font-[600]">
        {data && ((data.companyUsedQuota / data.companyQuota) * 100).toFixed(1)}
        <span className="text-[36px] font-[500]">% </span>
        <span className="text-[14px] font-[500]">
          {t('dataManagement.currentUsage.inUse')}
        </span>
      </p>
      <p className="mt-[33.5px] text-[#888888] text-[14px] font-[500]">
        <TitleAndVolume
          title={t('dataManagement.currentUsage.totalUsage')}
          volume={data?.companyQuota ?? 0}
        />
        {'  |  '}
        <TitleAndVolume
          title={t('dataManagement.currentUsage.inUse')}
          volume={data?.companyUsedQuota ?? 0}
        />
        {'  |  '}
        <TitleAndVolume
          title={t('dataManagement.currentUsage.available')}
          volume={data?.companyAvailableQuota ?? 0}
        />
      </p>
      <div className="relative flex gap-[2px] mt-[10px] w-full h-[12px] rounded-[6px] bg-[#EAECF2] overflow-clip">
        {data &&
          data.highStorageUsageAppList &&
          data.highStorageUsageAppList.map((app, index) => (
            <DataField
              key={app.id}
              width={`${((app.usage / data.companyQuota) * 100).toFixed(0)}`}
              backgroundColor={colorCodes[index % 4] ?? '#FEFEFE'}
            />
          ))}
        {data && data.highStorageUsageAppList && (
          <DataField
            key={'etc'}
            width={(
              ((data.companyUsedQuota -
                data.highStorageUsageAppList
                  .map((app) => app.usage)
                  .reduce((acc, curr) => acc + curr, 0)) /
                data.companyQuota) *
              100
            ).toFixed(0)}
            backgroundColor={'#B5C0D0'}
          />
        )}
      </div>
      <div className="relative flex mt-[10px] w-full items-center gap-[16px]">
        {data &&
          data.highStorageUsageAppList &&
          data.highStorageUsageAppList.map((app, index) => (
            <Legend
              key={app.id}
              color={colorCodes[index % 4] ?? '#FEFEFE'}
              title={app.name}
            />
          ))}
        <Legend color="#B5C0D0" title={t('dataManagement.currentUsage.etc')} />
      </div>
      <button
        className="flex items-center justify-center w-full h-[40px] mb-[-12px] mt-[20px] border border-solid border-[#9A9CA0] rounded-[8px] text-[#888888]"
        title={t('dataManagement.currentUsage.increseStorage')}
      >
        {t('dataManagement.currentUsage.increseStorage')}
        <ArrowTopRightOnSquareIcon className="size-[16px] ml-[10px]" />
      </button>
    </div>
  );
}

interface LegendProps {
  className?: string;
  color: string;
  title: string;
}

function Legend({ className, color, title }: LegendProps) {
  return (
    <div className={clsx(className, 'flex items-center py-[2px] pr-[4px]')}>
      <div
        className="size-[8px] rounded-[2px]"
        style={{ backgroundColor: color }}
      ></div>
      <span className="ml-[8px] font-[400] text-[14px] text-[#333333]">
        {title}
      </span>
    </div>
  );
}
