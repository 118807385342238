import { atom, createStore } from 'jotai';
import { NodeProps } from './interface';
import { getNodeId } from './Util';

const store = createStore();

export const treesAtom = atom<NodeProps[]>([]);
export const selectedNodesAtom = atom<NodeProps[]>([]);

export const addChilds = (trees: NodeProps[], node: NodeProps) => {
  const update = (item: NodeProps): NodeProps => {
    if (getNodeId(node) === getNodeId(item)) {
      return {
        ...item,
        childrenList:
          node.childrenList?.map((subItem) => {
            return { ...subItem, selected: item.selected };
          }) ?? [],
      };
    } else if (item.childrenList?.length) {
      return {
        ...item,
        childrenList: item.childrenList?.map(update),
      };
    }
    return item;
  };
  return trees.map(update);
};

const updateNode = (node: NodeProps, selected: boolean): NodeProps => {
  const updatedNode = { ...node, selected };
  if (node.childrenList) {
    updatedNode.childrenList =
      node.childrenList?.map((child) => updateNode(child, selected)) ?? [];
  }
  return updatedNode;
};

export const updateTrees = (
  trees: NodeProps[],
  node: NodeProps,
): NodeProps[] => {
  const updatedTrees = trees.map((item) => {
    if (getNodeId(node) === getNodeId(item)) {
      return updateNode(item, node.selected ?? false);
    } else if (item.childrenList) {
      return { ...item, childrenList: updateTrees(item.childrenList, node) };
    }
    return item;
  });

  const updateParents = (nodes: NodeProps[], nodeId: string): NodeProps[] => {
    const updatedNodes = nodes.map((node) => {
      if (node.childrenList) {
        const updatedChildren = updateParents(node.childrenList, nodeId);
        const allSelected =
          updatedChildren.length > 0
            ? updatedChildren.every((child) => child.selected)
            : node.selected;
        return {
          ...node,
          selected: allSelected,
          childrenList: updatedChildren,
        };
      }
      return node;
    });
    return updatedNodes;
  };

  return updateParents(updatedTrees, getNodeId(node));
};

export const getSelectedNodes = (nodes: NodeProps[]) => {
  const filteredNodes: NodeProps[] = [];

  const traverseTree = (node: NodeProps): void => {
    const hasChild = node.childrenList && node.childrenList.length > 0;
    const allChildrenSelected =
      hasChild && node.childrenList?.every((child) => child.selected);

    if (node.selected || allChildrenSelected) {
      filteredNodes.push({ ...node });
    } else {
      return (
        node.childrenList &&
        node.childrenList.forEach((child) => traverseTree(child))
      );
    }
  };

  nodes.forEach((node) => traverseTree(node));

  return filteredNodes;
};

export default store;
