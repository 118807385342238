import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { PaginationPrimitives } from './primitives';

export interface Props {
  className?: string;
  /**
   * @description 선택된 페이지 번호
   * index가 0부터 시작되는 페이지네이션의 경우 1을 더해주어야 함
   */
  currentPage: number;
  totalPage: number;
  maxPageCount?: number;
  onNextPage: (page: number) => void;
  onPreviousPage: (page: number) => void;
  onFirstPage: () => void;
  onLastPage: () => void;
  onClickPage: (page: number) => void;
}

export function Pagination({
  className,
  currentPage: defaultCurrentPage,
  totalPage,
  maxPageCount = 10,
  onNextPage,
  onPreviousPage,
  onFirstPage,
  onLastPage,
  onClickPage,
}: Props) {
  const [currentPage, setCurrentPage] = useState<number>(
    defaultCurrentPage + 1,
  );
  const getcurrentPageQuotient = () =>
    Math.floor(
      currentPage % maxPageCount !== 0
        ? currentPage / maxPageCount
        : currentPage / maxPageCount - 1,
    );

  const getPageBlockLength = () =>
    getcurrentPageQuotient() === Math.floor(totalPage / maxPageCount)
      ? totalPage % maxPageCount
      : maxPageCount;

  const pageList: number[] = Array.from(
    { length: getPageBlockLength() },
    (_, index) => index + getcurrentPageQuotient() * maxPageCount + 1,
  );

  const firstPageButtonClickHandler = () => {
    setCurrentPage(1);
    onFirstPage();
  };

  const lastPageButtonClickHandler = () => {
    setCurrentPage(totalPage);
    onLastPage();
  };

  const prevPageButtonClickHandler = () => {
    setCurrentPage((prev) => {
      if (prev !== 1) {
        onPreviousPage(prev - 2);
        return prev - 1;
      }

      return prev;
    });
  };

  const nextPageButtonClickHandler = () => {
    setCurrentPage((prev) => {
      if (prev !== totalPage) {
        onNextPage(prev);
        return prev + 1;
      }

      return prev;
    });
  };

  useEffect(() => {
    onClickPage(currentPage - 1);
  }, [currentPage, onClickPage]);

  const firstPageIndex = 1;

  return (
    <div className={clsx('flex items-center justify-center gap-2', className)}>
      <PaginationPrimitives.Button
        type="action"
        onClick={firstPageButtonClickHandler}
        title="btn_first_page"
        disabled={currentPage === firstPageIndex}
      >
        <ChevronDoubleLeftIcon
          className="size-[28px] p-[6px]"
          color={clsx({
            '#aaaaaa': currentPage === firstPageIndex,
            '#333333': currentPage !== firstPageIndex,
          })}
        />
      </PaginationPrimitives.Button>
      <PaginationPrimitives.Button
        type="action"
        onClick={prevPageButtonClickHandler}
        title="btn_prev_page"
        disabled={currentPage === firstPageIndex}
      >
        <ChevronLeftIcon
          className="size-[28px] p-[6px]"
          color={clsx({
            '#aaaaaa': currentPage === firstPageIndex,
            '#333333': currentPage !== firstPageIndex,
          })}
        />
      </PaginationPrimitives.Button>
      {pageList.map((page) => (
        <PaginationPrimitives.Button
          type="page"
          className={clsx(
            'text-sm size-[28px] border !border-solid rounded-md hover:rounded-md hover:bg-[#f4f4f4]',
            {
              '!border-[#363636] text-[#333333]': currentPage === page,
              '!border-white text-[#888888]': currentPage !== page,
            },
          )}
          onClick={() => setCurrentPage(page)}
          key={page}
        >
          {page}
        </PaginationPrimitives.Button>
      ))}
      <PaginationPrimitives.Button
        type="action"
        onClick={nextPageButtonClickHandler}
        title="btn_next_page"
        disabled={currentPage === totalPage}
      >
        <ChevronRightIcon
          className="size-[28px] p-[6px]"
          color={clsx({
            '#aaaaaa': currentPage === totalPage,
            '#333333': currentPage !== totalPage,
          })}
        />
      </PaginationPrimitives.Button>
      <PaginationPrimitives.Button
        type="action"
        onClick={lastPageButtonClickHandler}
        title="btn_prev_page"
        disabled={currentPage === totalPage}
      >
        <ChevronDoubleRightIcon
          className="size-[28px] p-[6px]"
          color={clsx({
            '#aaaaaa': currentPage === totalPage,
            '#333333': currentPage !== totalPage,
          })}
        />
      </PaginationPrimitives.Button>
    </div>
  );
}

export default Pagination;
