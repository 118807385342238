import {
  Cell,
  CellContext,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Header,
  HeaderContext,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';
import { clsx } from 'clsx';
import { ReactElement, useEffect, useState } from 'react';
import { useTableGenericContext } from './Context';

import styles from '../styles/Table.module.css';

export interface Props<T> {
  className?: string;
  header?: ReactElement | ((headerCell: Header<T, unknown>) => ReactElement);
  body?: ReactElement | ((bodyCell: Cell<T, unknown>) => ReactElement);
}

export function Contents<T>({
  className,
  header: headerCell,
  body: bodyCell,
}: Props<T>) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const { contents, columnDefs, selectable, onClickRow, onSelectContents } =
    useTableGenericContext<T>();

  useEffect(() => {
    onSelectContents &&
      onSelectContents(contents.filter((_, index) => rowSelection[index]));
  }, [rowSelection, contents, onSelectContents]);

  useEffect(() => {
    setRowSelection({});
    onSelectContents && onSelectContents([]);
  }, [contents, onSelectContents]);

  const selectColumn: ColumnDef<T> = {
    id: 'selectColumn',
    header: ({ table }: HeaderContext<T, unknown>) => (
      <div className="dop_checkbox size-full flex items-center justify-center">
        <input
          type="checkbox"
          id="select-all"
          defaultChecked={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
        <label htmlFor="select-all">{}</label>
      </div>
    ),
    cell: ({ row }: CellContext<T, unknown>) => (
      <div className="dop_checkbox size-full flex items-center justify-center">
        <input
          type="checkbox"
          id={`select-${row.index}`}
          defaultChecked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
        <label className="dop_checkbox" htmlFor={`select-${row.index}`}>
          {}
        </label>
      </div>
    ),
  };

  const table = useReactTable({
    data: contents,
    columns: selectable ? [selectColumn, ...columnDefs] : columnDefs,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: selectable,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  });

  return (
    <div className={styles.Table}>
      <table className={clsx(className)}>
        <thead className="border-y border-[#2C323A]">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                if (selectable && index === 0) {
                  return (
                    <th key={header.id} className="!h-[48px] !w-[36px]">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </th>
                  );
                } else if (headerCell) {
                  return typeof headerCell === 'function' ? (
                    <th
                      key={header.id}
                      style={{
                        width: `${header.getSize()}px`,
                      }}
                    >
                      {headerCell(header)}
                    </th>
                  ) : (
                    <th
                      key={header.id}
                      style={{
                        width: `${header.getSize()}px`,
                      }}
                    >
                      {headerCell}
                    </th>
                  );
                } else {
                  return (
                    <th
                      key={header.id}
                      style={{
                        width: `${header.getSize()}px`,
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </th>
                  );
                }
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <tr key={row.id} onClick={() => onClickRow && onClickRow(index)}>
              {row.getVisibleCells().map((cell, index) => {
                if (selectable && index === 0) {
                  return (
                    <td
                      key={cell.id}
                      className="!h-[48px] !hover:rounded-tl-[8px] !hover:rounded-bl-[8px]"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                } else if (bodyCell) {
                  return typeof bodyCell === 'function' ? (
                    <td key={cell.id}>{bodyCell(cell)}</td>
                  ) : (
                    <td key={cell.id}>{bodyCell}</td>
                  );
                } else {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
