import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { columnDefGenerator } from '@daouoffice/ui/lib/labs/Table';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { MemberDataInfo, StorageInfo } from '../../../../types';
import { StorageField } from '../StorageFiled';

export interface Props {
  contents: MemberDataInfo[];
  onChangeContentsByIndex?: (index: number, value: number, key: string) => void;
}

export function Table({ contents, onChangeContentsByIndex }: Props) {
  const { t } = useTranslation();

  const columnDefs: ColumnDef<MemberDataInfo>[] = [
    columnDefGenerator<MemberDataInfo>(
      'userName',
      t('globalconfig.data.member.field.name'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'userEmail',
      t('globalconfig.data.member.field.address'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'mail',
      t('globalconfig.data.member.dialog.field.mail'),
    ),
    {
      accessorKey: 'mailStorage',
      id: 'mailStorage',
      header: () => (
        <div className="flex items-center py-3 text-left gap-1">
          {t('globalconfig.data.member.dialog.addmailstorage')}
        </div>
      ),
      cell: (value: CellContext<MemberDataInfo, unknown>) => (
        <StorageField
          className="py-3 text-left flex items-center gap-1"
          defaultValue={(value.renderValue() as StorageInfo)?.size}
          onValueChange={(fieldValue) =>
            onChangeContentsByIndex &&
            onChangeContentsByIndex(value.row.index, fieldValue, 'mailStorage')
          }
          fileSizeType={(value.renderValue() as StorageInfo)?.sizeType ?? 'MB'}
          onFileSizeTypeChange={() => {}}
          withSizeSelector={false}
        />
      ),
    },
    columnDefGenerator<MemberDataInfo>(
      'webFolder',
      t('globalconfig.data.member.dialog.addfolderstorage'),
    ),
    {
      accessorKey: 'folderStorage',
      id: 'folderStorage',
      header: () => (
        <div className="flex items-center py-3 text-left gap-1">
          {t('globalconfig.data.member.dialog.field.addfolderstorage')}
        </div>
      ),
      cell: (value: CellContext<MemberDataInfo, unknown>) => (
        <StorageField
          className="py-3 text-left flex items-center gap-1"
          defaultValue={(value.renderValue() as StorageInfo)?.size}
          onValueChange={(fieldValue) =>
            onChangeContentsByIndex &&
            onChangeContentsByIndex(
              value.row.index,
              fieldValue,
              'folderStorage',
            )
          }
          fileSizeType={(value.renderValue() as StorageInfo)?.sizeType ?? 'MB'}
          onFileSizeTypeChange={() => {}}
          maxSize={10240}
          withSizeSelector={false}
        />
      ),
    },
  ];

  return contents.length === 0 ? (
    <div className="flex items-center justify-center border rounded-lg w-full h-[256px] text-lg">
      {t('globalconfig.table.data.null')}
    </div>
  ) : (
    <TablePrimitives.Root
      selectable={false}
      contents={contents}
      columnDefs={columnDefs}
    >
      <TablePrimitives.Contents />
    </TablePrimitives.Root>
  );
}

export default Table;
