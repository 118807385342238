/* eslint-disable react/jsx-no-comment-textnodes */
import { useAtom } from 'jotai';

import { CheckBox } from '@daouoffice/ui/lib/foundation/CheckBox/CheckBox';
import { Avatar } from '@daouoffice/ui/lib/foundation/Avatar';

import { NodeProps } from './interface';
import { useOrgChartContext } from './Provider';
import { treesAtom, updateTrees } from './Store';

export interface Props {
  node: NodeProps;
}

const MemberNode = ({ node }: Props) => {
  const context = useOrgChartContext();
  const { onNodeClick } = context.actions;
  const { useCheckbox, uuid } = context.state;
  const [, setTrees] = useAtom(treesAtom);
  const isChecked = node.selected;

  const toggleSelect = () => {
    setTrees((trees) =>
      updateTrees(trees, { ...node, selected: !node.selected }),
    );
  };

  const clickNode = () => {
    if (onNodeClick) onNodeClick(node);
  };

  return (
    <>
      <div onClick={clickNode} className="member" role="button" aria-hidden>
        <Avatar size={40} alt={node.name} src={node.profileImageUrl} />
        <i className="icon master"></i>
        <div className="info">
          <div className="main">
            <span className="name body_medium regular">{node.name}</span>
          </div>
        </div>
        <span
          className="ic ic_state_on away"
          style={{
            position: 'absolute',
            top: '0px',
            width: '8px',
            height: '8px',
            // background: presence.status ? 'lightgreen' : 'gray',
          }}
        ></span>
      </div>
      <span style={{ position: 'absolute', top: '15px', right: '0px' }}>
        {useCheckbox && (
          <CheckBox
            id={`${node.nodeType}_${node.id}_${uuid}`}
            onChange={toggleSelect}
            defaultChecked={isChecked}
          ></CheckBox>
        )}
      </span>
    </>
  );
};

export default MemberNode;
