import MemberNode from './MemberNode';
import DeptNode from './DeptNode';
import { NodeProps } from './interface';

export interface Props {
  node: NodeProps;
  isSearch?: boolean;
}

const Node = ({ node, isSearch }: Props) => {
  const isDepartment = node.nodeType === 'DEPARTMENT';
  return (
    <>
      <li className="list" data-id={node.id}>
        {isDepartment && <DeptNode node={node} isSearch={isSearch}></DeptNode>}
        {!isDepartment && <MemberNode node={node}></MemberNode>}
      </li>
    </>
  );
};

export default Node;
