import { Contents } from './components/Contents';
import { Root } from './components/Root';

export function DeptDataManage() {
  return (
    <Root>
      <Contents />
    </Root>
  );
}

export default DeptDataManage;
