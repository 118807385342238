'use client';

import * as React from 'react';
import { LOGIN_PAGE_URL } from '../../../constants';
import { fetchGraphQL } from '../../fetch/client';
import type { LoginUserData, MeResponse, UserData } from '../types';
import { AuthContext, type AuthContextValues } from './Context';

import * as userSessionQuery from './userSessionQuery';

export function Provider({ children }: React.PropsWithChildren) {
  const [userData, setUserData] = React.useState<LoginUserData | null>(null);
  const firstLoadedRef = React.useRef<boolean>(false);
  const clearStorages = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  React.useLayoutEffect(() => {
    if (!firstLoadedRef.current) {
      getSessionData()
        .then((data) => setUserData(data))
        .catch((e) => console.error(e));
      firstLoadedRef.current = true;
    }
  }, [firstLoadedRef]);

  const logout = () => {
    const logout = async () => {
      const response = await fetch('/api/portal/common/auth/logout', {
        method: 'POST',
      });

      if (response.ok) {
        setUserData(null);
        window.location.href = LOGIN_PAGE_URL;
        clearStorages();
      }
    };

    logout().catch((e) => console.log(`logout error: ${e}`));
  };

  const ctxVal: AuthContextValues = React.useMemo(
    () => ({
      get me() {
        return userData;
      },
      isLogin: () => userData !== null,
      logout: logout,
    }),
    [userData],
  );

  // user 정보를 못받아오면 로그인 페이지로 이동하지만, 한번 더 방어한다.
  if (userData === null) return;

  return <AuthContext.Provider value={ctxVal}>{children}</AuthContext.Provider>;
}

Provider.displayName = 'ClientAuthProvider';

export async function getSessionData() {
  const resp = await fetchGraphQL({
    query: userSessionQuery.Query,
    operationName: userSessionQuery.ID,
  });

  const data = (await resp.json()) as MeResponse;

  return userDataMapper(data.data.me);
}

function userDataMapper(data: UserData): LoginUserData {
  return {
    id: data.id,
    name: data.name,
    loginId: data.loginId,
    email: data.email ?? 'no-email',
    gradeName: data.userInfo?.grade?.name ?? 'no-grade',
    departmentMemberList: [],
    status: data.status ?? 'no-status',
    companyId: data.company ? `${data.company.id}` : 'no-company',
    profileImageUrl: data.userInfo.profileImageUrl ?? '',
    isBusinessPortalAccessible: data.isAccessibleBusinessPortal ?? false,
    isSettingAccessible: data.isAccessibleSettingPortal ?? false,
    isAccessibleArchivingService: data.isAccessibleArchivingService ?? false,
  };
}

export default Provider;
